@import url(https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@500;700;900&display=swap);
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  word-break: keep-all;
}
html {
  scroll-behavior: smooth;
}
body {
  color: #343a40;
  background-color: #fff;
  font-family: Noto Serif KR, -apple-system, BlinkMacSystemFont, Roboto,
    Helvetica, sans-serif;
  font-size: 16px;
}
main {
  display: flex;
  flex-direction: column;
}
main > .Content_Container {
  min-height: 100vh;
}
a {
  all: unset;
  color: #343a40;
  cursor: pointer;
}
input {
  -webkit-user-select: auto;
}
.hide-scroll::-webkit-scrollbar {
  display: none;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
}
*::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

